import Dashboard from './containers/Dashboard';
import Events from './containers/Events';
import BulkRefund from './containers/BulkRefund';
import PublishClassResults from './containers/PublishClassResults';
import ImportEiEvent from './containers/ei/ImportEvent';
import Venues from './containers/Venues';
import Users from './containers/Users';
import Horses from './containers/Horses';
import Payments from './containers/Payments';
import Orders from './containers/Orders';
import ReconciliationLog from './containers/ReconciliationLog';
import OldDressageTests from './containers/OldDressageTests';
import DressageTests from './containers/DressageTests';
import ClassTypes from './containers/ClassTypes';
import Tests from './containers/Tests';
import EditDetails from './containers/details/EditDetails';
import DeleteDetails from './containers/details/DeleteDetails';
import TableListing from './containers/details/TableListing';
import ViewDetails from './containers/details/ViewDetails';
import ViewEventDetails from './containers/details/ViewEventDetails';
import ViewReconciliationLogDetails from './containers/details/ViewReconciliationLogDetails';
import ViewReconciliationDetails from './containers/details/ViewReconciliationDetails';
import ViewTeamCompetitionDetails from './containers/details/ViewTeamCompetitionDetails';
import ViewTeamDetails from './containers/details/ViewTeamDetails';
import ViewEventClassDetails from './containers/details/ViewEventClassDetails';
import SetupEventClassDetails from './containers/details/SetupEventClassDetails';
import ViewPaymentDetails from './containers/details/ViewPaymentDetails';
import ViewHorseDetails from './containers/details/ViewHorseDetails';
import ViewUserDetails from './containers/details/ViewUserDetails';
import ViewOrderDetails from './containers/details/ViewOrderDetails';
import RefundWorldnet from './containers/details/RefundWorldnet';
import SplitEventClass from './containers/details/SplitEventClass';
import RefreshEvent from './containers/details/RefreshEvent';
import CreateDetails from './containers/details/CreateDetails';
import CreateEventClass from './containers/details/CreateEventClass';
import CloneEvent from './containers/details/CloneEvent';
import CreateEventEntrant from './containers/details/CreateEventEntrant';
import CreateLateEntry from './containers/details/CreateLateEntry';
import SchedulerDashboard from './scheduler/containers/SchedulerDashboard';
import ReportDashboard from './reports/containers/ReportsDashboard';
import ReportPage from './reports/containers/ReportPage';
import EventEntrantsReportPage from './reports/containers/EventEntrantsReportPage';
import EventReportsPage from './reports/containers/EventReportsPage';
import Arenas from './scheduler/containers/Arenas';
import Riders from './scheduler/containers/Riders';
import ClassSettings from './scheduler/containers/ClassSettings';
import ClassScheduling from './scheduler/containers/ClassScheduling';
import GenericTable from './containers/details/GenericTable';
import EditEventEntrant from './containers/details/EditEventEntrant';
import ViewEventEntrant from './containers/details/ViewEventEntrant';
import WithdrawEntryDetails from './containers/details/WithdrawEntryDetails';
import ApplicationEntryDetails from './containers/details/ApplicationEntryDetails';
import EditArenaDetails from './scheduler/containers/EditArenaDetails';

import ScoringDashboard from './scoring/containers/ScoringDashboard';
import ScoringResults from './scoring/containers/ScoringResults';
import EditScoreDetails from './scoring/components/EditScoreDetails';

import DressageScoring from './scoring/containers/DressageScoring';
import ShowJumpingScoring from './scoring/containers/ShowJumpingScoring';
import PageNotFound from './containers/PageNotFound';
import AssignTeamMember from './containers/details/AssignTeamMember';
import CreateDressageTest from './containers/details/CreateDressageTest';
import ViewDressageTest from './containers/details/ViewDressageTest';
import CrossCountryScoring from './scoring/containers/CrossCountryScoring';
import CreateCompetency from './containers/details/CreateCompetency';
import ViewCompetency from './containers/details/ViewCompetency';
import MigrateUnderAge from './containers/details/MigrateUnderAge';
import CreateVolunteer from './containers/details/CreateVolunteer';

const routes = [
    { path: '/', component: Dashboard },
    { path: '/events', component: Events },
    { path: '/venues', component: Venues },
    { path: '/users', component: Users },
    { path: '/horses', component: Horses },
    { path: '/payments', component: Payments },
    { path: '/orders', component: Orders },
    { path: '/classtypes', component: ClassTypes },
    { path: '/users/migrateua', component: MigrateUnderAge },
    { path: '/dressagetests', component: DressageTests },
    { path: '/olddressagetests', component: OldDressageTests },
    { path: '/reports', component: ReportDashboard },
    { path: '/view/report/:id', component: ReportPage },    
    { path: '/evententrants/event/:id', component: EventEntrantsReportPage },    
    { path: '/eventreports/event/:id', component: EventReportsPage },    
    { path: '/tests', component: Tests },
    { path: '/:type', component: GenericTable },
    { path: '/:type/:id/:action/:title', component: TableListing },
    { path: '/view/horse/:id', component: ViewHorseDetails },
    { path: '/bulkrefund/eventclass/:id', component: BulkRefund },
    { path: '/publishresults/eventclass/:id', component: PublishClassResults },
    // { path: '/view/event/:id', component: EditEventDetails },
    { path: '/view/user/:id', component: ViewUserDetails },
    // { path: '/view/document/:id', component: ViewDocument },
    { path: '/edit/arena/:id', component: EditArenaDetails },
    { path: '/edit/eventscoringresults/:id', component: EditScoreDetails },
    { path: '/view/payment/:id', component: ViewPaymentDetails },
    { path: '/view/order/:id', component: ViewOrderDetails },
    { path: '/view/teamcompetition/:id', component: ViewTeamCompetitionDetails },
    { path: '/view/team/:id', component: ViewTeamDetails },    
    { path: '/view/dressagetest/:id', component: ViewDressageTest },    
    { path: '/view/event/:id', component: ViewEventDetails },
    { path: '/view/reconciliationlog/:id', component: ViewReconciliationLogDetails },
    { path: '/reconciliationlog', component: ReconciliationLog },
    { path: '/view/reconciliations/:id', component: ViewReconciliationDetails },
    { path: '/view/competencies/:id', component: ViewCompetency },
    { path: '/view/eventclass/:id', component: ViewEventClassDetails },
    { path: '/setup/scoringeventclasses/:id', component: SetupEventClassDetails },
    { path: '/view/:type/:id', component: ViewDetails },
    { path: '/view/evententrant/:id', component: ViewEventEntrant },
    { path: '/refundworldnet/worldnet/:id', component: RefundWorldnet },
    { path: '/manage/evententrant/:id', component: EditEventEntrant },
    { path: '/view/evententrantapplication/:id', component: ApplicationEntryDetails },
    { path: '/entry/withdraw/:id', component: WithdrawEntryDetails },
    { path: '/edit/:type/:id', component: EditDetails },
    { path: '/delete/:type/:id', component: DeleteDetails },
    { path: '/refresh/event/:id', component: RefreshEvent },
    { path: '/create/eventclass', component: CreateEventClass },
    { path: '/create/evententrant', component: CreateEventEntrant },
    { path: '/create/teammember', component: AssignTeamMember },
    { path: '/create/lateentry', component: CreateLateEntry },
    { path: '/create/volunteers', component: CreateVolunteer },
    { path: '/create/competencies', component: CreateCompetency },
    { path: '/create/dressagetest', component: CreateDressageTest },
    { path: '/create/:type', component: CreateDetails },
    { path: '/import/ei', component: ImportEiEvent },
    { path: '/settings', component: PageNotFound },
    { path: '/split/eventclasses/:id', component: SplitEventClass },
    { path: '/clone/event/:id', component: CloneEvent },

    // { path: '/auditlog', component: PageNotFound },
    // { path: '/bulkrefund/eventclass/:id', component: PageNotFound }
];

const schedulerroutes = [
    { path: '/event/:id', component: SchedulerDashboard },
    { path: '/:id/arenas', component: Arenas },
    { path: '/:id/classsettings', component: ClassSettings },
    { path: '/:id/scheduleclasses', component: ClassScheduling },
    { path: '/:id/riders', component: Riders },
];

const scoringroutes = [
    { path: '/event/:id', component: ScoringDashboard },
    { path: '/results/:id', component: ScoringResults },
    { path: '/:id/dressage', component: DressageScoring },
    { path: '/:id/showjumping', component: ShowJumpingScoring },
    { path: '/:id/crosscountry', component: CrossCountryScoring },
];

export { routes, schedulerroutes, scoringroutes };