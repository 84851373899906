import * as React from 'react';
import Panel from '../../components/containers/Panel';
import Box from '@mui/material/Box';
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Title from "../Title";
import Description from "../Description";
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Back from "../../components/buttons/Back";

export default function CreateVolunteer() {
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState("");
    const [added, setAdded] = React.useState(false);
    const [searchParams] = useSearchParams();

    if(!searchParams.has('eventid')) {
      return (
        <Alert severity="error">Missing eventid in request</Alert>
      )
    }

    const getFormFields = () => {
        return [    
            {
              attribute: "userid",
              label: "Select Member Name  To Volunteer",
              component: 'autocomplete-remote',
              validationType: "string",
              validations: {
                required: true,
              },
              optionDef: {
                type: "server-search",
                name: "fullname",
                url: "/admin/user/auto",
                operation: "like",
                min: 2
              },
              optionConfig: {
                key: "id",
                value: "id",
                label: "name",
              },
              col: {
                sm: 12,
              },
            }
          ];
    }
    
    const updateForm = (updates) => {
        setError("");
        setAdded(false);
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    }

  
    const confirm = () => {
      // make sure the info has been provided
      if(!form['userid'] ) {
        return setError("Please enter the members name and select it from the drop down list");
      }

      axios.post("/admin/volunteers/add", {
        userid: form['userid'],
        eventid: searchParams.get('eventid')
      })
      .then(response => {
          if (response.data.error) {
              return setError("Problem adding volunteer to this event: " + response.data.error)
          } 
          updateForm({ userid: form['userid']});
          setAdded(true);
      })
      .catch(error => {
          setError("Unexpected error trying to add this volunteer: " + error);
      });
    }      

    return (
        <Panel>
            <React.Fragment>            
                <Title>Select Member To Volunteer</Title>
                <Description>Please select the member to volunteer for this event. Note: if you start typing the persons name then the list will autocomplete.</Description>
                <FormBuilder fields={getFormFields()} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                {added != "" && <Alert severity="success" sx={{ mt:1}}>This person has been added to the volunteer list for this event</Alert>}
                <Box sx={{mt:2}}><Back/>{!added && <Primary sx={{ ml: 2 }} startIcon={<AddIcon/>} title={"Add"} onClick={() => { confirm() }}/>}</Box>
            </React.Fragment>

        </Panel>
    );
   

}