import * as React from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';

import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import TabPanel from '../../components/containers/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import EventDescription from "../../components/eventing/EventDescription";
import EventResults from "../../components/eventing/EventResults";
import useLocalStorage from '../../app/useLocalStorage';
import { MetaTable } from '../../components/table/MetaTable';
import EntriesTable from '../../components/eventing/EntriesTable';
import FileUploadDialog from '../../components/containers/FileUploadDialog';
import { EventEntryFilters }  from '../../app/form/Definitions/EventEntryFilters';
import { ApplicationsFilters }  from '../../app/form/Definitions/ApplicationsFilters';
import { ArchivedFilters }  from '../../app/form/Definitions/ArchivedFilters';
import { EventEntryStablingFilters }  from '../../app/form/Definitions/EventEntryStablingFilters';

const store = sessionStorage;

export default function ViewEventDetails() {
    const { id } = useParams();
    const [event, setEvent] = React.useState(null);
    const [value, setValue] = useLocalStorage('view-event-tab', 0);
    const [uploadOpen, setUploadOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [uploadResults, setUploadResults]  = React.useState([]);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();    

    store.setItem('current-event-id', id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    const dataUsed = (event) => {
        setEvent(event);
    }   

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    };
      
   const getClasses = () => {
        return event.Eventclasses.map(ec => {
            return ec.Classtype;
        });
   }

   const getHookupIndex = () => {
        if(event.stablefeesapply == 1) {
            return 8;
        }
        return 7;
   }

   const upload = () => {
    setIsLoading(false);
    setUploadOpen(true);
    } 

   const confirmUploadFiles = (files) => {
    setIsLoading(true);
    const formData = new FormData();
    console.log(files[0].File);
    formData.append('file', files[0]);
    setUploadResults([]);
    axios({
        method: "post",
        url: `/admin/event/uploadvolunteers/${id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    }).then(result => {
        if(result.data.error) {
            setIsLoading(false);
            return enqueueSnackbar(result.data.message, { variant: 'error'});
        }
        setIsLoading(false);
        setUploadOpen(false);
        setUploadResults(result.data.records);
        enqueueSnackbar("Volunteer flags uploaded", { variant: 'success'});
        navigate(0);
    });
}

    /**
     * Foaled 2007 Height 163cm Colour Chestnut Total Points = 2
     * Owned By Harry Rowcliffe Bred By Mrs Pippa Wiegersma Gender Gelding
     * Sire Graf Quidam Dam Mazey Day
     */
    return (
        <React.Fragment>
            <Panel>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                        variant="scrollable" 
                        scrollButtons
                        value={value}   
                        onChange={handleChange} 
                        aria-label="event details"
                    >
                        <Tab label="Event Details" {...a11yProps(0)} />
                        <Tab label="Description" {...a11yProps(1)} />
                        <Tab label="Classes" {...a11yProps(2)} />
                        <Tab label="Contacts" {...a11yProps(3)} />
                        <Tab label="Entries" {...a11yProps(4)} />
                        <Tab label="Results" {...a11yProps(5)} />
                        <Tab label="Approvals" {...a11yProps(6)} />
                        <Tab label="Stabling" {...a11yProps(7)} />
                        <Tab label="Hookups" {...a11yProps(8)} />
                        <Tab label="Volunteers" {...a11yProps(9)} />
                        <Tab label="Late Entries" {...a11yProps(10)} />
                        <Tab label="Teams" {...a11yProps(11)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                        <Form id={id} objtype={"event"} readonly={true} showbuttons={true} onData={dataUsed} noTitle={true}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {event && <EventDescription id={id} name={event.name} description={event.notes}/>}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {event && <MetaTable 
                        type="eventclass" 
                        canCreate={true}    
                        dataUrl={"/admin/eventclass?eventid="+id} 
                        filters={ArchivedFilters}
                        defaultFilter={{ archived: 0}}                   
                        createLabel={"Add Event Class"}
                    />}
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {event && <MetaTable 
                        type="contacts" 
                        canCreate={true}    
                        dataUrl={"/admin/contacts?eventid="+id} 
                        filters={ArchivedFilters}
                        defaultFilter={{ archived: 0}}          
                        idname="eventid"
                        rememberSearch="eventContacts"
                        createLabel={"Add Contact"}
                    />}
                    {event && <MetaTable 
                        type="photographers" 
                        canCreate={true}    
                        dataUrl={"/admin/photographers?eventid="+id} 
                        filters={ArchivedFilters}
                        defaultFilter={{ archived: 0}}          
                        idname="eventid"
                        createLabel={"Add Photographer"}
                    />}
                </TabPanel>              
                <TabPanel value={value} index={4}>
                    {event && <EntriesTable dataUrl={`/admin/event/${id}/entries`} 
                                        type="evententrant" filter={EventEntryFilters} 
                                        defaultFilter={ {status: 'ENTRYCOMPLETED'} }
                                        idvalue={id}
                                        idname="eventid"
                                        canCreate={true}
                                    />}
                </TabPanel>
                <TabPanel value={value} index={5} lazy={false}>
                    {event && <EventResults id={id} name={event.name} classes={getClasses()}/>}
                </TabPanel>
                <TabPanel value={value} index={6}>
                {event && <EntriesTable dataUrl={`/admin/event/${id}/entries`} 
                    type="evententrantapplication" filter={ApplicationsFilters}
                    query={{ isapplication: 1, 'ee.archived':0}} canCreate={false}
                    />}
                </TabPanel>
                {event && <TabPanel value={value} index={7}>
                    {event && <EntriesTable dataUrl={`/admin/event/${id}/stabling`} 
                    type="evententrantstabling" createLabel={"Add Stabling"}
                    filter={EventEntryStablingFilters} defaultFilter={ {extrastatus: 'STABLINGCOMPLETED'} }
                    />}
                </TabPanel>}
                {event && <TabPanel value={value} index={8}>
                    {event && <EntriesTable dataUrl={`/admin/event/${id}/hookup`} 
                    type="evententranthookup" 
                    filter={EventEntryStablingFilters} defaultFilter={ {extrastatus: 'STABLINGCOMPLETED'} }
                />}
                </TabPanel>}
                <TabPanel value={value} index={9}>
                {event && <>
                <FileUploadDialog 
                    title="Select Or Drag Drop File"
                    description="Please click below to select the updated Volunteer file to upload or drag and drop the file into the box below."
                    open={uploadOpen}
                    confirmLabel="Upload Volunteer File"
                    uploadButtonText="Select Volunteer CSV File To Upload"
                    onCancel={() => setUploadOpen(false)}
                    onConfirm={confirmUploadFiles}
                    loading={isLoading}
                    loadingMessage="Please wait while we upload the file"
                />
                <MetaTable 
                    canExport
                    type="volunteers" 
                    canCreate={true}    
                    createLabel={"Add Volunteer"}
                    dataUrl={"/admin/volunteers?eventid="+id} 
                    filters={ArchivedFilters}
                    defaultFilter={{ archived: 0}}          
                    idname="eventid"
                    canImport={() => setUploadOpen(true)}
                /></>}                
            </TabPanel>   
            <TabPanel value={value} index={10}>
                {event && <MetaTable 
                    type="lateentry" 
                    canCreate
                    dataUrl={"/admin/lateentry?eventid="+id} 
                    idname="eventid"                    
                />}                
            </TabPanel>   
            <TabPanel value={value} index={11}>
                {event && <MetaTable 
                    type="teamcompetition" 
                    canCreate
                    dataUrl={"/admin/teamcompetition?eventid="+id} 
                    idname="eventid"
                    createLabel={"Add Team Competition"}                    
                />}                
            </TabPanel>   
            </Panel>              
            
        </React.Fragment>
    )
}